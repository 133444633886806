<template>
    <section class="join">
        <v-container>
            <!--<router-link :to="{name:'Home'}" class="join__back d-md-none">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="21.0468" y1="12.5607" x2="10.4402" y2="1.95406" stroke="black" stroke-width="3"/>
                    <path d="M21.0957 10.9858L10.7381 21.3435" stroke="black" stroke-width="3"/>
                </svg>
                <span>назад</span>
            </router-link>-->

            <transition name="route" mode="out-in">
                <div class="d-flex  flex-column flex-md-row join__content">
                    <div class="join__left d-flex align-center justify-center mb-8 mb-md-0">
                        <join-confirmation v-if="isSubscribed"/>
                        <div v-else>
                            <h3 class="mb-8 mt-md-0 text-center text-md-left">{{ texts.joinH3 }}</h3>
                            <h4 class="mb-8 text-center text-md-left">
                                {{ texts.joinH4 }}
                            </h4>

                            <v-form class="mt-7 mt-lg-9 join__form" @submit.prevent="submit">
                                <div class="join__form-inputs">
                                    <v-row>
                                        <v-col cols="12">
                                            <label>
                                                <input v-model="form.NAME" type="text" :placeholder="texts.placeholderName" maxlength="64">
                                            </label>
                                        </v-col>
                                        <v-col cols="12" class="mt-2">
                                            <label>
                                                <input v-model="form.EMAIL" type="email"
                                                       name="email" placeholder="Email"
                                                       maxlength="64">
                                            </label>
                                        </v-col>

                                    </v-row>
                                </div>

                                <label class="join__form-upload">
                                    <input ref="upload" type="file" accept="image/*" @change="previewUpload">
                                    {{ texts.joinPhoto }}
                                </label>
                                <!--<img v-if="uploadPreview" :src="uploadPreview" class="join__upload-preview"/>-->

                                <p v-if="error" class="mt-3 mb-0 join__error" v-html="error"></p>

                                <button type="submit" class="ma-auto mt-6 join__form-btn">
                                    <pulse-loader v-if="isLoading" :loading="true" color="#fff" size="5px"/>
                                    <span v-else>{{ texts.send }}</span>
                                </button>
                                <p v-html="texts.policy" class="mt-6 text-center text-md-left">
                                </p>

                            </v-form>

                        </div>
                    </div>
                    <div class="join__right d-flex align-end">
                        <v-img contain src="~@/assets/join/right-img.jpg"/>
                    </div>
                </div>
            </transition>

            <!--<router-link :to="{name:'Home'}" class="join__back d-none d-md-flex">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="21.0468" y1="12.5607" x2="10.4402" y2="1.95406" stroke="black" stroke-width="3"/>
                    <path d="M21.0957 10.9858L10.7381 21.3435" stroke="black" stroke-width="3"/>
                </svg>
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="21.0468" y1="12.5607" x2="10.4402" y2="1.95406" stroke="black" stroke-width="3"/>
                    <path d="M21.0957 10.9858L10.7381 21.3435" stroke="black" stroke-width="3"/>
                </svg>
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="21.0468" y1="12.5607" x2="10.4402" y2="1.95406" stroke="black" stroke-width="3"/>
                    <path d="M21.0957 10.9858L10.7381 21.3435" stroke="black" stroke-width="3"/>
                </svg>
                назад
            </router-link>-->
        </v-container>
    </section>
</template>

<script>
import JoinConfirmation from "@/components/join-confirmation";
import PulseLoader from 'vue-spinner/src/PulseLoader';
import axios from "axios";
import Config from "@/config";
import BtnLink from "../components/btn-link";

export default {
    components: {BtnLink, JoinConfirmation, PulseLoader},
    data() {
        return {
            isSubscribed: false,
            form: {
                NAME: "",
                EMAIL: "",
            },
            error: "",
            isLoading: false,
            uploadPreview: null
        }
    },
    computed: {
        texts() {
            return {
                joinH3: this.$store.state.common?.commontext?.JOIN_HEAD,
                joinH4: this.$store.state.common?.commontext?.JOIN_HEAD2,
                joinPhoto: this.$store.state.common?.commontext?.JOIN_PHOTO,
                policy: this.$store.state.common?.commontext?.POLICY,
                send: this.$store.state.common?.buttons?.SEND,
                placeholderName: this.$store.state.common?.commontext?.JOIN_NAME,
                valid1: this.$store.state.common?.commontext?.JOIN_VALID1,
                valid2: this.$store.state.common?.commontext?.JOIN_VALID2,
                valid3: this.$store.state.common?.commontext?.JOIN_VALID3,
            }
        },
    },
    methods: {
        previewUpload() {
            const [file] = this.$refs.upload.files;
            if (file) {
                this.uploadPreview = URL.createObjectURL(file);
            }
        },
        submit() {

            const reName = /^.{2,}$/;
            if (!reName.test(String(this.form.NAME).toLowerCase())) {
                return this.error = this.texts?.valid1 ? this.texts?.valid1 : 'Пожалуйста, введите Ваше имя';
            }

            const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!reEmail.test(String(this.form.EMAIL).toLowerCase())) {
                return this.error = this.texts?.valid2 ? this.texts?.valid2 : "Пожалуйста, введите Ваш email";
            }

            if (!this.uploadPreview) {
                return this.error = this.texts?.valid3 ? this.texts?.valid3 : "Пожалуйста, загрузите фото";
            }

            //if ( !this.form.agree) return this.error = "Необходимо принять пользовательское соглашение";

            this.error = false;
            this.isLoading = true;

            const formData = new FormData();
            Object.keys(this.form).forEach(key => formData.append(key, this.form[key]));
            const imagefile = document.querySelector('input[type="file"]');
            formData.append("FILE", imagefile.files[0]);

            return axios.post(Config.SUBMIT_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    console.log('API Response: ', response);
                    if (response.data?.error) {
                        if (response.data.code === Config.ERROR_CODE_EXISTS) this.error = "Такой email телефона уже зарегистрирован.<br />Регистрация возможна только один раз."
                        if (response.data.code === Config.ERROR_CODE_WRONG_PARAMS) this.error = "Отправлены неверные параметры.<br />Пожалуйста, проверьте и попробуйте снова."
                        else this.error = "Произошла неизвестная ошибка.<br />Пожалуйста, попробуйте отправить еще раз.";

                    } else {
                        this.isSubscribed = true;

                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }
                })
                .catch((error) => {
                    console.warn('API request error: ', error);
                    this.error = error;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
}
</script>

<style lang="scss">

.join {
    background-color: $joinBg;
    //background-image: url("~@/assets/join-bg.jpeg");
    //background-size: cover;
    //background-position: center center;
    padding: 100px 0 0;
    @include up($md) {
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100vh;
        max-height: 900px;
    }
    box-sizing: border-box;

    section {
        width: 100%;
    }

    & .container {
        @include up($md) {
            height: 100%;
            display: flex;
        }
    }

    a,
    h3 {
        color: $blue-1;
    }

    p {
        font-size: 14px;
        line-height: 100%;
    }

    &__right {
        @include up($md) {
            width: 70%;
        }
    }

    &__left {
        @include up($md) {
            width: 30%;
            padding: 5%;
        }
    }

    &__back {
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include up($md) {
            position: absolute;
            left: 6%;
            bottom: 11%;
        }
        @include up($xl) {
            bottom: 10%;
        }

        svg {
            transform: rotate(-90deg);

            @include up($md) {
                transform: rotate(180deg);
            }
        }

        span {
            position: relative;
            display: inline-block;
            top: -2px;
            margin-left: 8px;
            font-weight: 400;
            font-size: 18px;
        }
    }

    &__form {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;
        @include up($sm) {
            align-items: center;
            max-width: 340px;
            //margin: 0 auto;
        }

        @include up($md) {
            margin: unset;
        }

        &-upload {
            margin-top: 18px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 49px;
            box-sizing: border-box;
            border-radius: 5px;
            line-height: 100%;
            font-size: 15px;
            background-color: $white;
            padding: 17px 6px !important;
            cursor: pointer;
            @include transition();

            &:hover {

            }

            input {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 0;
                opacity: 0;
                cursor: pointer;
            }
        }

        &-btn {
            padding: 10px 40px;
            border-radius: 5px;
            border: 1px solid transparent;
            background-color: $blue-1;
            color: $white;
            @include transition();

            &:hover {
                background-color: transparent;
                border: 1px solid $blue-1;
                color: $blue-1;
            }
        }
    }

    &__form-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        & label {
            display: block;
            height: 49px;
            border: 0.5px solid #140e14;
            background-color: $white;
            box-sizing: border-box;
            border-radius: 5px;
            line-height: 49px;
            overflow: hidden;
        }

        & input {
            width: 100%;
            padding: 0 22px;
            line-height: 49px;
            font-size: 15px;
            outline: none;
        }
    }

    /*
        h1 {
            font-size: 29px;

            @include up($lg) {
                font-size: 66px;
            }
        }

        h3 {
            font-size: 18px;
            line-height: 18px;
            text-transform: uppercase;

            @include up($lg) {
                font-size: 25px;
                line-height: 25px;
            }
        }


        &__link-wrap {
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            text-transform: none;

            @include up($lg) {
                font-size: 18px;
                line-height: 18px;
                letter-spacing: 0.03em;
            }

            a {
                text-decoration: underline;
                font-size: inherit;
                line-height: inherit;
            }
        }

        &__form {
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include up($sm) {
                align-items: center;
                max-width: 340px;
                margin: 0 auto;
            }

            @include up($md) {
                max-width: 690px;
            }

            &-inputs {
                width: 100%;

                .row {
                    @include up($md) {
                        margin: -6px;
                    }

                    & > div {
                        padding-top: 6px;
                        padding-bottom: 6px;

                        @include up($md) {
                            padding: 6px;
                        }
                    }
                }

                @include up($md) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                label {
                    display: block;
                    height: 49px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    line-height: 49px;
                    overflow: hidden;


                }
            }

            &-upload {
                margin-top: 18px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                height: 49px;
                box-sizing: border-box;
                border-radius: 5px;
                line-height: 49px;
                font-size: 15px;

                padding: 17px 0 !important;
                cursor: pointer;
                @include transition();

                &:hover {

                }

                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            .btn-link {
                margin-top: 11px;
                width: 100%;
                padding: 0 24.5px;
                height: 54px;
                font-size: 15px;

                @include up($md) {
                    margin-top: 13px;
                    height: 60px;
                    max-width: 340px;
                }
            }
        }

        &__error {
            font-size: 18px;
            margin: 0 auto;
            color: red;
            text-align: center;
        }

        &__upload-preview {
            display: block;
            margin: 10px auto;
            max-width: 120px;
            max-height: 120px;
        }

        */
}
</style>